import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Trade5 from "../../images/Trade5.png";
import Trade1 from "../../images/Trade1.png";
import Trade8 from "../../images/Trade8.png";
import Trade6 from "../../images/Trade6.png";
import TradeGroup from "../../images/trade-group.gif";
import Title from "../../images/trade-title.png";

export default function TradeAround() {
  return (
    <Layout>
      <Helmet>
        <title>Trade Around | Nathan Pasko</title>
        <meta name="description" content="" />
      </Helmet>
      <div className="project-page">
        <ul className="horizontal-scroll">
          <li>
            <picture>
              <img src={Trade5} height="960" width="540" alt="screenshot" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Trade1} height="960" width="540" alt="screenshot" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Trade8} height="960" width="540" alt="screenshot" />
            </picture>
          </li>
          <li>
            <picture>
              <img src={Trade6} height="960" width="540" alt="screenshot" />
            </picture>
          </li>
        </ul>
        <picture>
          <img src={Title} alt="" height="" width="" style={{background: 'white'}} />
        </picture>
        <h1>Trade Around</h1>
        <p>
          After playing <em>Link's Awakening DX</em>, I made Trade Around as an homage to
          the Legend of Zelda series' trading side-quests, in which players find
          and trade otherwise useless items to NPCs across the entirety of the
          game's main story. Though the trading quests of LoZ are largely
          optional, I was struck by the simple mechanics and fascinated by the
          way an ongoing sidequest was running like a chain throughout the rest
          of the game. Trading doesn't involve violence, either, and I wanted to
          make another non-violent game after completing <Link to="/project/s-pet">S. Pet</Link>.
        </p>
        <iframe frameborder="0" src="https://itch.io/embed/268841?border_width=0&amp;fg_color=000&amp;link_color=e0e0e0&amp;border_color=fff" width="206" height="165"><a href="https://avknights.itch.io/trade-around">Trade Around by A.V. Knights</a></iframe>
        <picture>
          <img src={TradeGroup} alt="" height="" width="" />
        </picture>
      </div>
    </Layout>
  );
}
